import objectKeysToCamelCase from '@grebban/utils/object/keysToCamelCase';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { inServer } from '@sportson/core-web/constants';
import Events from '@sportson/core-web/libs/Events';
import { PageEvents, ProductEvents } from '@sportson/core-web/libs/Events/constants';
import { ResolveSlug } from '@sportson/core-web/libs/GrebbCommerceAPI/Content';
import { formatLocaleAlternates } from '@sportson/core-web/state/page/helpers';
import getQueryParams from '@sportson/core-web/utils/getQueryParams';
// import DataLayer from '@sportson/core-web/components/Tracking/DataLayer';

export default createAsyncThunk('page/resolvePage', async (data, { getState, fulfillWithValue, rejectWithValue }) => {
    // @TODO do we need mutexLock here?

    const { applicationId, currency } = getState().application.site;

    const { slug, location } = data;

    // @TODO refactor/move to utils?
    const { p: selectedPartNoParameter } = getQueryParams(location) || {};
    const selectedPartNoState = location.state?.presetPartNo;
    const parameters = {};
    if (selectedPartNoState || selectedPartNoParameter) {
        parameters.selected_variant_part_no = selectedPartNoState || selectedPartNoParameter;
    }

    // @TODO error handling?
    // if (!applicationId) {
    //     return rejectWithValue({ error: 'Unknown error' });
    // }

    const response = await ResolveSlug(applicationId, slug, parameters);
    const { alternate, data: pageData, product_data: productData, relationships, template, type } = response.data;

    if (type === 'redirect') {
        // @TODO: check if this works for both SSR and Client
        let redirectSlug = response.data.to;
        if (location.search.charAt(0) === '?') {
            redirectSlug += location.search;
        }

        return fulfillWithValue({
            type,
            data: {
                to: redirectSlug,
                status: response.status,
            },
            status: 'idle',
        });
    }

    if (type === 'product' && productData) {
        // @TODO Maybe use formatting/data fetching helpers
        // fetchProductData(action.payload?.data);

        // Todo: fix Datalayer product structure as it currently is not correctly handling variants
        if (!inServer) {
            try {
                Events.trigger(ProductEvents.VIEW, {
                    data: productData,
                });
            } catch (e) {
                //
            }
        }
        pageData.product = productData;
    }

    // @TODO error handling if no route is found?
    const alternates = formatLocaleAlternates(alternate || [], pageData.localizations);
    return fulfillWithValue({
        data: pageData,
        applicationId: applicationId || null,
        localeAlternates: alternates,
        relationships,
        search: location.search,
        template,
        type,
        isFetching: false, // Todo, maybe remove this?
        status: 'idle',
        currencyCode: currency.code,
    });
});

const pending = (state) => {
    state.isFetching = true; // Todo, maybe remove this?
    state.status = 'pending';
};

const fulfilled = (state, action) => {
    const camelizedData = objectKeysToCamelCase(action.payload, {
        recursive: true,
        modifyInputObject: false,
    });

    // Todo: check for duplicate history entries in GTM
    if (camelizedData.type !== 'product') {
        const { template, type, currencyCode, applicationId } = camelizedData;
        const { title } = camelizedData.data;
        try {
            Events.trigger(PageEvents.VIEW, {
                title,
                template,
                type,
                currencyCode,
                applicationId,
            });
        } catch (e) {
            //
        }
    }

    return {
        ...state,
        ...camelizedData,
    };
};

const rejected = (state, action) => {
    state.isFetching = false; // Todo, maybe remove this?
    state.status = 'idle';
};

export const resolvePageStateCallbacks = {
    pending,
    fulfilled,
    rejected,
};

import React from 'react';
import PropTypes from 'prop-types';
import styled from '@grebban/style-system-react';
import { withRouter } from 'react-router-dom';
import { close as closeOverlay } from '@sportson/core-web/state/application/overlay';
import useAppDispatch from '@sportson/core-web/hooks/useAppDispatch';

const StyledWrapper = styled('div')`
    display: flex;
    position: fixed;
    width: 100%;
    inset: 0px;
    user-select: ${({ $hidden }) => ($hidden ? 'none' : 'auto')};
    pointer-events: ${({ $hidden }) => ($hidden ? 'none' : 'auto')};
`;

const StyledAnimationWrapper = styled('div')`
    position: absolute;
    width: 100%;
    height: 100%;
    transition: all var(--transition-primary-slow);

    // Fades into view
    &.fade {
        opacity: ${({ showing }) => (showing ? 1 : 0)};
    }

    // Slide in from top
    &.from-top {
        transform: ${({ showing }) => (showing ? 'translateY(0%)' : 'translateY(-100%)')};
    }

    // Slide in from left
    &.from-left {
        transform: ${({ showing }) => (showing ? 'translateX(0%)' : 'translateX(-100%)')};
    }

    // Slide in from right
    &.from-right {
        transform: ${({ showing }) => (showing ? 'translateX(0%)' : 'translateX(100%)')};
    }

    // Slide in from bottom
    &.from-bottom {
        transform: ${({ showing }) => (showing ? 'translateY(0%)' : 'translateY(100%)')};
    }
`;

const StyledBackground = styled('div')`
    position: absolute;
    display: flex;
    height: 100%;
    width: 100%;
    z-index: -1;
    background-color: var(--color-base-black);
    transition: opacity var(--transition-primary);
    ${({ top }) => `top: ${top};`}
`;

const Wrapper = ({ showing, transition, children, top = '0px' }) => {
    const dispatch = useAppDispatch();

    // Close overlay on background click
    const onBackgroundClick = ({ target }) =>
        target.className &&
        typeof target.className.includes !== 'undefined' &&
        target.className.includes(transition) &&
        dispatch(closeOverlay());

    return (
        <StyledWrapper zIndex="var(--zindex-overlay)">
            <StyledAnimationWrapper
                role="button"
                ariaLabel="Close with the escape key or by clicking outside this overlay."
                className={transition}
                showing={showing}
                tabIndex="0"
                onClick={onBackgroundClick}
                onKeyDown={(ev) => {
                    if (ev.key === 'Escape') {
                        dispatch(closeOverlay());
                        onBackgroundClick(ev);
                    }
                }}
            >
                {children}
            </StyledAnimationWrapper>
            <StyledBackground top={top} opacity={showing ? '.3' : '0'} />
        </StyledWrapper>
    );
};

Wrapper.propTypes = {
    children: PropTypes.node.isRequired,
    showing: PropTypes.bool.isRequired,
    transition: PropTypes.string.isRequired,
    top: PropTypes.string,
};

export default withRouter(Wrapper);

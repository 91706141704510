import React from 'react';
import PropTypes from 'prop-types';
import styled from '@grebban/style-system-react';
import { withRouter } from 'react-router-dom';

const StyledWrapper = styled('div')`
    display: flex;
    position: relative;
    width: 100%;
`;

const StyledAnimationWrapper = styled('div')`
    position: fixed;
    width: 100%;
    height: 100%;
    transition: all var(--transition-primary-slow);

    // Fades into view
    &.fade {
        opacity: ${({ showing }) => (showing ? 1 : 0)};
    }

    // Slide in from top
    &.from-top {
        transform: ${({ showing }) => (showing ? 'translateY(0%)' : 'translateY(-100%)')};
    }

    // Slide in from left
    &.from-left {
        transform: ${({ showing }) => (showing ? 'translateX(0%)' : 'translateX(-100%)')};
    }

    // Slide in from right
    &.from-right {
        transform: ${({ showing }) => (showing ? 'translateX(0%)' : 'translateX(100%)')};
    }

    // Slide in from bottom
    &.from-bottom {
        transform: ${({ showing }) => (showing ? 'translateY(0%)' : 'translateY(100%)')};
    }
`;

const Wrapper = ({ showing, transition, children }) => (
    <StyledWrapper zIndex="var(--zindex-overlay)">
        <StyledAnimationWrapper className={transition} showing={showing}>
            {children}
        </StyledAnimationWrapper>
    </StyledWrapper>
);

Wrapper.propTypes = {
    children: PropTypes.node.isRequired,
    showing: PropTypes.bool.isRequired,
    transition: PropTypes.string.isRequired,
    top: PropTypes.string,
};

export default withRouter(Wrapper);

import React from 'react';
import { Helmet } from 'react-helmet-async';

import decodeHtmlEntities from '@sportson/core-web/utils/decodeHTMLEntities';

import useAppSelector from '@sportson/core-web/hooks/useAppSelector';

import formatMetaDescription from '@sportson/core-web/components/metadata/utils/formatMetaDescription';

// @TODO: This should be moved to config when the structure is set
const NO_INDEX_PAGE_TEMPLATES = ['search_page'];

// @TODO: This should be moved to config when the structure is set
const NO_FOLLOW_PAGE_TEMPLATES = [];

const PageMeta = () => {
    const applicationId = useAppSelector(({ application }) => application?.site?.applicationId);
    const languages = useAppSelector(({ application }) => application?.site?.languages || []);

    const currentSiteDomain = (languages.find((lang) => lang.id === applicationId)?.uri || '')
        .replace(/^\/+/, '')
        .replace(/\/+$/, '');

    // Site data
    const siteProtocol = useAppSelector(({ application }) => application?.site?.protocol || '');

    // Page data
    const pageTemplate = useAppSelector(({ page }) => page?.data?.template || '');
    const pagePermalink = useAppSelector(({ page }) => page?.data?.permalink || '');
    const pageSeoCanonical = useAppSelector(({ page }) => page?.data?.seo?.canonical || '');
    const pageSeoTitle = useAppSelector(({ page }) => page?.data?.seo?.title || '');
    const pageTitle = useAppSelector(({ page }) => page?.data?.title || '');
    const pageSeoDescription = useAppSelector(({ page }) => page?.data?.seo?.description || '');
    const pageSeoTwitterImage = useAppSelector(({ page }) => page?.data?.seo?.twitterImage || '');
    const pageSeoOpenGraphImage = useAppSelector(({ page }) => page?.data?.seo?.ogImage || '');
    const pageNoIndex = useAppSelector(({ page }) => page?.data?.seo?.noIndex === '1');
    const pageNoFollow = useAppSelector(({ page }) => page?.data?.seo?.noFollow === '1');

    // Parsed meta values
    const noIndex = pageNoIndex || NO_INDEX_PAGE_TEMPLATES.includes(pageTemplate) ? 'noindex' : '';
    const noFollow = pageNoFollow || NO_FOLLOW_PAGE_TEMPLATES.includes(pageTemplate) ? 'nofollow' : '';
    const canonical =
        (pageSeoCanonical && pageSeoCanonical?.startsWith('/')
            ? `${siteProtocol}${currentSiteDomain}${pageSeoCanonical}`
            : pageSeoCanonical) || (pagePermalink ? `${siteProtocol}${currentSiteDomain}${pagePermalink}` : '');
    const robots = `${noIndex}${noIndex && noFollow ? ', ' : ''}${noFollow}`;
    const title = decodeHtmlEntities(pageSeoTitle || pageTitle || '');
    const description = formatMetaDescription(pageSeoDescription);

    return (
        <Helmet>
            {/* General meta data */}
            {title && <title>{title}</title>}
            {canonical && <link rel="canonical" href={canonical} />}
            {description && <meta name="description" content={description} />}
            {robots && <meta name="robots" content={robots} />}

            {/* OpenGraph data */}
            {title && <meta property="og:title" content={title} />}
            {description && <meta property="og:description" content={description} />}
            {pageSeoOpenGraphImage && (
                <meta
                    property="og:image"
                    content={`${pageSeoOpenGraphImage}?q=70&fit=clip&fm=jpg&auto=format&w=1200&h=675}`}
                />
            )}

            {/* Twitter data */}
            {title && <meta property="twitter:title" content={title} />}
            {description && <meta property="twitter:description" content={description} />}
            {pageSeoTwitterImage && (
                <meta
                    property="twitter:image"
                    content={`${pageSeoTwitterImage}?q=70&fit=clip&fm=jpg&auto=format&w=1200&h=675}`}
                />
            )}
        </Helmet>
    );
};

export default PageMeta;

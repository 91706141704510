import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import useIntersectionObserver from '@sportson/core-web/hooks/useIntersectionObserver';
import { generateSizes, generateSrc, generateSrcSet } from '@sportson/core-web/libs/image';
import { asArray } from '@sportson/core-web/utils/array';
import { getMediaUrl } from '@sportson/core-web/utils/getMediaUrl';
import { isObject } from '@sportson/core-web/utils/object';
import { toHash } from '@sportson/core-web/utils/string';
import { inBrowser, isSupportingLazy } from '@sportson/core-web/constants';
import styled from 'libs/styled';

const Wrapper = styled('div')`
    position: relative;
    width: 100%;
    height: 100%;
`;

const InnerWrapper = styled('div')`
    position: absolute;
`;

const StyledImage = styled('img')();

const cache = {};

const getImageProps = ({ srcSet, sizes, src, isSupportingWebp }) => {
    const result = {
        src,
        srcSet,
        sizes: generateSizes(sizes),
    };

    if (isObject(src)) {
        const { url, width, height, ...imgParams } = src;
        const imageUrl = getMediaUrl(url);
        const widths = asArray(width);
        const heights = asArray(height);
        result.src = generateSrc(
            imageUrl,
            {
                auto: 'format',
                w: widths[widths.length - 1],
                h: heights[heights.length - 1],
                fm: src.format || src.f,
                bg: src.background,
                q: src.quality || 70,
                ...imgParams,
            },
            isSupportingWebp,
        );

        if (!srcSet && widths.length > 1) {
            result.srcSet = generateSrcSet(result.src, widths, heights, isSupportingWebp);
        }
    }

    return result;
};

/**
 *
 * @typedef {object} Source
 * @property {string} url - Url of image, same as src in a normal img tag.
 * @property {(number | number[])} [width] - Resize image to specified width. if its a array it will also generate srcset attr.
 * @property {(number | number[])} [height] - Resize image to specified height.
 * @property {number} [quality=70] - Output image quality.
 * @property {('jpg' | 'png' | 'webp' | 'avif')} [format] - Output image format.
 * @property {string} [background] - Applies a background color to output image.
 */

/**
 * Base Image
 *
 * @param {('auto' | 'lazy' | 'eager')} [loading='lazy'] - Lazy = Nativ lazyloading with polyfill. Auto = use native without pollyfill. Eager = render directly.
 * @param {func} [onLoad] - Callback that fires after image is loaded.
 * @param {string} [placeholder] - Loading placeholder image, defaults to a blurred version of src.
 * @param {string | string[]} [sizes] - Specifies the width of the image relative to viewport through different breakpoints.
 * @param {(string | Source)} src - Image source. If specified as an object, component will generate srcSet based on width array.
 *
 * Example:
 *
 *     <Image
 *         src={{
 *             url: 'foo.png',
 *             width: [200, 300, 400],
 *         }}
 *         loading="lazy"
 *         sizes={['100vw', null, '50vw']}
 *     />
 */

const Image = ({
    alt,
    loading,
    onLoad,
    placeholder,
    sizes,
    src,
    srcSet,
    backgroundColor,
    objectFit,
    lazyLoadWrapperStyling,
    title,
    fallbackImageAsPlaceholder,
    ...rest
}) => {
    const { t } = useTranslation();
    const isSupportingWebp = useSelector(({ application }) => application.site.isSupportingWebp);
    const cacheKey = toHash(JSON.stringify(src));
    const cachedImage = cache[cacheKey];
    const image = cachedImage || getImageProps({ sizes, src: src || placeholder, srcSet, isSupportingWebp });
    const isCritical = loading === 'eager' || !!cachedImage;
    const useIOSupport = !isCritical && !isSupportingLazy;
    const addNoScript = !isCritical;
    const isVisible = isCritical || inBrowser;

    const [observe, unobserve] = useIntersectionObserver(
        (entry) => {
            if (entry.intersectionRatio > 0 || entry.isIntersecting) {
                const image = entry.target;

                if (image.dataset.src && image.src !== image.dataset.src) {
                    image.src = image.dataset.src;
                }

                if (image.dataset.srcSet) {
                    image.srcset = image.dataset.srcSet;
                }

                if (image.dataset.sizes) {
                    image.sizes = image.dataset.sizes;
                }

                requestAnimationFrame(() => {
                    if (image.dataset.src) {
                        delete image.dataset.src;
                    }
                    if (image.dataset.srcSet) {
                        delete image.dataset.srcSet;
                    }
                    if (image.dataset.sizes) {
                        delete image.dataset.sizes;
                    }

                    unobserve();
                });
            }
        },
        { rootMargin: '200px' },
    );

    const handleLoad = (event) => {
        if (!isCritical) {
            event.target.style.opacity = 1;
            event.target.previousElementSibling.style.backgroundImage = '';
        }

        if (!cache[cacheKey]) {
            cache[cacheKey] = {
                src: event.target.src,
                srcSet: event.target.srcset,
                sizes: event.target.sizes,
            };
        }

        onLoad && onLoad(event);
    };

    const backgroundImage = isCritical
        ? undefined
        : (placeholder && `url(${placeholder})`) || fallbackImageAsPlaceholder
          ? `url(${generateSrc(image.src, { w: 25, q: 80 }, isSupportingWebp)})`
          : undefined;

    const backgroundSize = objectFit || 'contain';
    const backgroundPosition = 'center center';
    const lazyImageStyle = {};

    if (!isCritical && backgroundImage && backgroundImage !== 'none') {
        lazyImageStyle.backgroundRepeat = 'no-repeat';
        lazyImageStyle.backgroundSize = backgroundSize;
        lazyImageStyle.backgroundPosition = backgroundPosition;
    }

    const imgProps = useIOSupport
        ? {
              'data-sizes': image.sizes,
              'data-src-set': srcSet || image.srcSet,
              'data-src': image.src,
              ref: observe,
          }
        : {
              sizes: image.sizes,
              src: image.src,
              srcSet: srcSet || image.srcSet,
          };

    const criticalStyle = isCritical ? {} : { opacity: 0, transition: 'opacity 200ms ease-in' };

    return (
        <Wrapper style={{ backgroundColor }}>
            <InnerWrapper style={{ backgroundImage }} {...lazyImageStyle} {...lazyLoadWrapperStyling} />
            {isVisible && (
                <StyledImage
                    fetchpriority={loading === 'eager' ? 'high' : 'auto'}
                    alt={alt || title || t('missing_image_title')}
                    key={image.src}
                    width="100%"
                    height="100%"
                    verticalAlign="top"
                    objectFit={objectFit}
                    objectPosition="center"
                    backgroundColor={backgroundColor}
                    {...rest}
                    {...imgProps}
                    {...criticalStyle}
                    loading={loading}
                    onLoad={handleLoad}
                />
            )}
            {addNoScript && (
                <noscript>
                    <StyledImage
                        src={image.src}
                        alt={alt || title || t('missing_image_title')}
                        position="absolute"
                        top="0"
                        left="0"
                        width="100%"
                        height="100%"
                        objectFit={objectFit}
                        objectPosition="center"
                        backgroundColor={backgroundColor}
                        {...rest}
                        {...imgProps}
                        {...criticalStyle}
                    />
                </noscript>
            )}
        </Wrapper>
    );
};

Image.propTypes = {
    alt: PropTypes.string,
    backgroundColor: PropTypes.string,
    fallbackImageAsPlaceholder: PropTypes.bool,
    lazyLoadWrapperStyling: PropTypes.shape({
        top: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
        left: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
        bottom: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
        right: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    }),
    loading: PropTypes.oneOf(['auto', 'lazy', 'eager']),
    objectFit: PropTypes.string,
    onLoad: PropTypes.func,
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    sizes: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    src: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            url: PropTypes.string.isRequired,
            width: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
            height: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
            quality: PropTypes.number,
            format: PropTypes.oneOf(['jpg', 'png', 'webp', 'avif']),
            background: PropTypes.string,
        }),
    ]).isRequired,
    srcSet: PropTypes.string,
    title: PropTypes.string,
};

Image.defaultProps = {
    alt: 'Image title missing',
    backgroundColor: 'transparent',
    fallbackImageAsPlaceholder: true,
    lazyLoadWrapperStyling: {
        top: '0px',
        bottom: '0px',
        right: '0px',
        left: '0px',
    },
    loading: 'lazy',
    objectFit: 'contain',
    onLoad: () => null,
    placeholder: null,
    sizes: null,
    srcSet: null,
    title: null,
};

export default Image;

import React, { useEffect, useRef, useMemo } from 'react';
import Wrapper from 'Overlay/Wrapper';
import { shallowEqual } from 'react-redux';
import loadable from '@loadable/component';
import Loading from 'components/Loading';
import useAppDispatch from '@sportson/core-web/hooks/useAppDispatch';
import { allow as allowScroll, disallow as disallowScroll } from '@sportson/core-web/state/application/scroll';
import { TransitionGroup, Transition } from 'react-transition-group';
import useAppSelector from '@sportson/core-web/hooks/useAppSelector';
import usePrevious from '@grebban/react-hooks/usePrevious';

// This is where we add new overlays
const overlayViews = {
    exampleOverlay: loadable(() => import('Overlay/views/Example'), { fallback: <Loading /> }),
    basketOverlay: loadable(() => import('Overlay/views/BasketOverlay'), { fallback: <Loading /> }),
    searchOverlay: loadable(() => import('Overlay/views/SearchOverlay'), { fallback: <Loading /> }),
    filterOverlay: loadable(() => import('Overlay/views/FilterOverlay'), { fallback: <Loading /> }),
    storeSelectorOverlay: loadable(() => import('Overlay/views/StoreSelectorOverlay'), { fallback: <Loading /> }),
    partialPaymentOverlay: loadable(() => import('Overlay/views/PartialPaymentOverlay'), { fallback: <Loading /> }),
    sortProductGridOverlay: loadable(() => import('Overlay/views/SortProductGridOverlay'), { fallback: <Loading /> }),
    categoriesOverlay: loadable(() => import('Overlay/views/CategoriesOverlay'), { fallback: <Loading /> }),
    articlesCollectionOverlay: loadable(() => import('Overlay/views/ArticlesCollectionOverlay'), {
        fallback: <Loading />,
    }),
    myAccountLoginOverlay: loadable(() => import('Overlay/views/MyAccountLoginOverlay'), { fallback: <Loading /> }),
};

// This is where we specify overlays with locked scroll
const overlayViewsWithLockedScroll = [
    'exampleOverlay',
    'basketOverlay',
    'filterOverlay',
    'searchOverlay',
    'filterOverlay',
    'sizeGuideOverlay',
    'checkoutErrorOverlay',
    'wrongSiteOverlay',
    'storeSelectorOverlay',
    'partialPaymentOverlay',
    'sortProductGridOverlay',
    'categoriesOverlay',
    'articlesCollectionOverlay',
    'myAccountLoginOverlay',
];

const Overlay = () => {
    const dispatch = useAppDispatch();
    const view = useAppSelector(({ application }) => application?.overlay?.view || '');
    const transition = useAppSelector(({ application }) => application?.overlay?.transition || '');
    const data = useAppSelector(({ application }) => application?.overlay?.data || {}, shallowEqual);
    const previousView = usePrevious(view);
    const reference = useRef(null);

    // Get the content component that should be used for the active overlay
    const OverlayComponent = overlayViews?.[view];

    // Lock scroll if any of these overlay views are active
    const overlayViewHasLockedScroll = useMemo(() => overlayViewsWithLockedScroll.includes(view), [view]);

    // Handle "scrollable" state
    useEffect(() => {
        view
            ? overlayViewHasLockedScroll && dispatch(disallowScroll(view))
            : overlayViewsWithLockedScroll.includes(previousView) && dispatch(allowScroll(previousView));
    }, [overlayViewHasLockedScroll]);

    return (
        <TransitionGroup>
            {OverlayComponent && (
                <Transition nodeRef={reference.current} in={!!OverlayComponent} timeout={{ enter: 0, exit: 300 }}>
                    {(transitionState) => (
                        <Wrapper
                            top={
                                view === 'searchOverlay' && [
                                    'var(--header-height)',
                                    null,
                                    null,
                                    null,
                                    null,
                                    'var(--header-search-height, 0)',
                                ]
                            }
                            ref={reference}
                            showing={transitionState === 'entered'}
                            transition={transition}
                        >
                            <OverlayComponent {...data} />
                        </Wrapper>
                    )}
                </Transition>
            )}
        </TransitionGroup>
    );
};

export default Overlay;
